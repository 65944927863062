import moment from 'moment';

type HighChartParams = {
    data: [number],
    title: string,
    marker: boolean,
    lineWidth?: number
}


// eslint-disable-next-line
type HighChartConfig = {
    params: [HighChartParams]
}

// eslint-disable-next-line
const generateHighChartParams = (data: [number], title: string, marker: boolean, lineWidth?: number): HighChartParams => {
    return {data, title, lineWidth, marker}
}

export const configureHighChart = (params: HighChartParams) => {

    const chart_height = '450px';
    return {
        chart: {
            type: 'line',
            zoomType: 'x',
            renderTo: 'container',
            height: chart_height
        },
        title: {
            text: '',
            style: {
                display: 'none'
            }
        },
        xAxis: {
            type: 'datetime',
            title: {
                enabled: true,
                text: 'Date'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true
        },
        yAxis: [{
            title: {
                text: params.title
            }
        }],
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 100,
            y: 70,
            floating: true,
            borderWidth: 1
        },
        tooltip: {
            xDateFormat: '%Y-%m-%d %H:%S',
            shared: true
        },
        plotOptions: {
            series: {
                marker: {
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: true
                        },
                        enabled: false
                    }
                },
            }
        },
        series: [{
            name: params.title,
            color: 'rgb(105,105,105)',
            lineWidth: params.lineWidth,
            marker: {
                enabled: params.marker,
                symbol: 'circle',
                radius: 1
            },
            data: params.data
        }]
    };
};


export const setQueryValue = (urlQuery, name, value) => {
    if (value == null) {
        urlQuery.delete(name)
    } else {
        urlQuery.set(name, value);
    }
}

export const redirect = (urlQuery, navigate) => {
    const newPath = `${window.location.pathname}?${urlQuery.toString()}`;
    navigate(newPath);
}

export const reducePath = (path) => {
    const pathArray = path.split("/");
    return pathArray.splice(0, pathArray.length - 1).join("/");
}

export const base64_specId_convert = (id_number) => {
    let id_string = window.atob(id_number);

    let id_uuid = id_string.split(':')[1].split('|')[0];
    let id_spec = id_string.split(':')[1].split('|')[1];

    return {
        uuid: id_uuid,
        id: id_spec,
        ql_id: id_number
    };
}

const default_time_format = "DD/MM/YYYY HH:mm";

export const startOfYearFrom = (years: number) => moment().startOf('year').subtract(years, 'years').format("YYYY-MM-DDTHH:mm:ss") + "Z";

export const unixConverter = (epoch: number) => moment(epoch).format(default_time_format);

export const unixConverterApi = (epoch: number) => moment(epoch).format("YYYY-MM-DDThh:mm:ss") + "Z"

export const unixConverterEncode = (date) => parseInt(moment(date, default_time_format).format('x'));

export const unixConverterYear = (date_MM_DD_YYYY: string) => moment(date_MM_DD_YYYY, 'MM-DD-YYYY').format("YYYY-MM-DDThh:mm:ss") + "Z";

export const unixConverterYearUK = (date_DD_MM_YYYY: string) => moment(date_DD_MM_YYYY, 'DD-MM-YYYY').format("YYYY-MM-DDThh:mm:ss") + "Z";

export const unixConverterYear_strategy = (date_YYYY_MM_DD: string) => moment(date_YYYY_MM_DD, 'YYYY-MM-DD').format('DD/MM/YYYY');

export const unixConverterNoTime = (date) => (Boolean(date) ? moment(date, default_time_format).format('DD/MM/YYYY') : '')

export const current_time = () => moment().format(default_time_format);

const format_YYYY_MM_YY = (val) => parseInt(moment(val, 'YYYY-MM-DD').format('x'));


const format_string = (val) => moment(`${val}`, 'x').format(default_time_format)

export const formatHistoricalAPI = (flags: any[], product: string): any[] => {
    const endFlag = product.includes("RAP") ? "Up" : "Down";

    const descFlags = flags.sort((a, b) => {
        return a.dts - b.dts
    })

    const indexEndFlags = descFlags.reduce((a, b, c) => {
        if (c === 0 || c === (descFlags.length - 1) || b.flag === endFlag) {
            a.push(c)
        }
        return a
    }, []);

    let fls: any[] = [];

    for (let i = 0; i < indexEndFlags.length; i++) {
        const startPosition = i === 0 ? indexEndFlags[i] : indexEndFlags[i] + 1;
        const reducedFlag = descFlags.slice(startPosition, indexEndFlags[i + 1]);

        const reducedFlagNew = reducedFlag.map((f) => {
            f['dts'] = unixConverter(f['dts'])
            f['endFlag'] = descFlags[indexEndFlags[i + 1]]['flag'];
            f['endPrice'] = descFlags[indexEndFlags[i + 1]]['price'];
            f['endTime'] = unixConverter(descFlags[indexEndFlags[i + 1]]['dts']);
            return f
        })
        fls.push(reducedFlagNew);
    }

    return fls
}

export const DeleteCookie = (cookieId, domain = ".ad.algodynamix.com") => {
    document.cookie = cookieId + `=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=${domain};`;
}

export interface CsvData {
    symbol: string,
    dts: string,
    price: number,
    endTime: number | 'Open',
    endPrice: number | 'Open'
}

export const csvDownloadElement = (sym: string, csvPageHeader: string[], csvTableHeader: string[], data: CsvData[], downloadTitle = '') => {
    const csvData = data.map((instrument) => {
        const {symbol, dts,  price, endTime = 'Open', endPrice = 'Open'} = instrument;
        const startDate = moment(dts, default_time_format).format(default_time_format);
        const endDate = endTime === 'Open' ? endTime : moment(endTime,default_time_format).format(default_time_format);
        return `${symbol},${startDate},${price}, ,${endDate},${endPrice}`
    })

    const csvCombined = csvPageHeader.concat(csvTableHeader, csvData);

    const csvReady = csvCombined.join("\n");

    if (data.length !== 0) {
        const encodeCSV = encodeURIComponent(csvReady);
        const hiddenHTMLElement = document.createElement('a');
        hiddenHTMLElement.href = ("data:text/csv;charset=utf-8,\uFEFF" + encodeCSV);
        if (downloadTitle && downloadTitle !== '') {
            hiddenHTMLElement.download = (`AlgoDynamix Download ${new Date()} symbol: ${sym}-${downloadTitle}.csv`);
        } else {
            hiddenHTMLElement.download = (`AlgoDynamix Download ${new Date()} symbol: ${sym}.csv`);
        }
        return hiddenHTMLElement;
    } else {
        return null;
    }
}

export const addFlagPnl = (flags: any[]) => {
    return flags.map((flag) => {

        const {price, endPrice, splitValue = 1} = flag;

        const rawPnl = endPrice - price;
        const splitRawPnl = rawPnl / splitValue

        return {...flag, rawPnl, splitRawPnl}

    });
}

export const formatAdjustStockSplit = (histData: any[], splitData: any[]) => {

    const historicalFormatted = histData.map((flag) => {
        const {endTime, dts} = flag;

        const [endTimeFormatted, dtsFormatted] = [endTime, dts].map(t => (unixConverterEncode(t)));

        return {...flag, "endTime": endTimeFormatted, "dts": dtsFormatted}
    })

    const splitDataFormatted = splitData.map(data => {
        const {splitDate} = data;

        return {...data, "splitDate": format_YYYY_MM_YY(splitDate)}
    })

    const historicalDataSplit = adjustSplitCrossOver(historicalFormatted, splitDataFormatted, 0);

    return historicalDataSplit.map(f => ({...f, "endTime": format_string(f.endTime), "dts": format_string(f.dts)}))
}

const adjustSplitCrossOver = (historical: any[], splitData: any[], index) => {

    if (index >= splitData.length) return historical

    const {splitDate, newShares, oldShares} = splitData[index]

    const split = newShares / oldShares

    const crossOverDates = historical.reduce((acc, val) => {

        const {endTime, dts, price} = val;

        if (endTime > splitDate && splitDate > dts) {
            return acc.concat({...val, "price": price / split, "split": split})
        }

        return acc.concat({...val, "split": 1})

    }, [])

    return adjustSplitCrossOver(crossOverDates, splitData, index + 1)

}
