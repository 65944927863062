import {FC, Fragment, useEffect, useState} from 'react';
import {Box, Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {makeStyles} from 'tss-react/mui';
import DialogueModal from '../../../common/DialogueModal';
import {
    addFlagPnl,
    CsvData,
    formatAdjustStockSplit,
    startOfYearFrom,
    unixConverter,
    unixConverterEncode
} from '../../../../services/utils';
import {productNamespace} from '../../../../namespace/namespace';
import {Metric} from './Metrics';
import moment from 'moment'
import TabsGeneral, {TabsGeneralData} from '../../../common/TabsGeneral';
import StrategyModalContainer from './StrategyModalContainer';
import StrategyRowContent from './StrategyRowContent';
import StrategyKey from './StrategyKey.json'
import {IFlagRust} from './ProductsCard';
import DownloadCsv from '../../../common/DownloadCsv';
import {
    HistoricalData,
    Trade,
    useGetCompositeHistoricalDataQuery,
    useGetHistoricalDataQuery,
    useGetStockSplitQuery,
    useGetStrategyDataQuery
} from '../../../../services/api';
import Loading from '../../../common/Loading';
import {endFlagFromProduct, getProductKey, startFlagFromProduct} from "./utils";

// maxium number of years to download
const MAX_YEARS = 6;

const useStyles = makeStyles()((theme: Theme) => ({
    card: {
        margin: theme.spacing(2),
        width: '100%'
    },
    histTable: {
        backgroundColor: 'rgb(255,255,255)',
        height: '100%'
    },
    histGraph: {
        height: '400px'
    },
    tableRow: {
        height: '1.5em'
    },
    graph: {
        height: '100%'
    },
    graphRow: {
        height: '100%',
        marginBottom: '1px'
    },
    subGraph: {
        width: '150px',
        height: ' 150px',
        backgroundColor: 'rgba(60, 77, 94,1)',
        borderRadius: '50%',
        textAlign: 'center',
        color: 'rgb(255,255,255)',
        paddingTop: '40px',
        marginLeft: "35%",
        marginTop: '0.5rem',
        marginBottom: '0.3rem',
    },
    subGraphContainer: {
        width: '150px',
        height: ' 150px',
        backgroundColor: 'rgba(81,109,145,1)',
        borderRadius: '50%',
        textAlign: 'center',
        color: 'rgb(255,255,255)',
        paddingTop: '40px',
        marginBottom: '0.3rem',
        marginLeft: "25%"
    },
    subHeader: {
        //backgroundColor: 'rgba(60, 77, 94,0.9)',
        backgroundColor: "rgba(30, 71, 118, 1)",
        color: 'rgb(255,255,255)',
        padding: '0.5rem'
        // height: '1.2rem'
    },
    modalContent: {
        backgroundColor: theme.palette.grey[100],
        height: "100%"
    },
    historicalTableRow: {
        maxHeight: '10px'
    },
    gridCustomContainer: {
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridTemplateRows: '100%',
        height: "99%",
        alignContent: "centre"
    },
    gridCustomColumn: {
        marginTop: "25%",
        alignContent: "centre"
    },
    modalContainer: {
        height: "75 vh",
        width: "98.5%",
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gridTemplateRows: "50% 50%",
        gridGap: "10px",
        alignContent: "center"
    },
    topLeft: {
        gridColumn: "1/2",
        gridRow: "1",
    },
    topMiddle: {
        gridColumn: "2/3",
        gridRow: "1",
    },
    rightCol: {
        gridColumn: "3/4",
        gridRow: "1/3",
        alignContent: "center"
    },
    bottomLeft: {
        gridColumn: "1/3",
        gridRow: "2",
    },
    leftCol: {
        gridColumn: "1/2",
        gridRow: "1/3",
        alignContent: "center"
    },
    topRight: {
        gridColumn: "2/3",
        gridRow: "1/2"
    },
    bottomRight: {
        gridColumn: "2/3",
        gridRow: "2/3"
    },
    tab: {
        textTransform: 'none',
        fontSize: '1.3rem',
        color: '#050505',
        '& span': {
            fontWeight: 'bold'
        }
    },
    piUp: {
        color: '#1D914F', // Green
    },
    end: {
        color: '#E49962', // Orange
    },
    rapDown: {
        color: 'black',
    }
}));

interface HistoricalModalContentProps {
    histData: any[]
    currentData: CurrentFlagData[]
    startTime?: string
    endTime?: string
    openData: any[]
    symbol: string
    endFlag: string
    product: string
    stockSplit?: any[]
    isComposite?: boolean,
}

const combineHistoricalCurrent = (hist_data: HistoricalData[] = [], cur_data: CurrentFlagData[] = []): CsvData[] => {

    // cur_data data has no symbol, extract it from hist_data
    let symbol = '';
    if (hist_data.length > 0) {
        symbol = hist_data[0].symbol;
    }

    const addFlagStatusAndSymbol = (input: any[], val: string = 'Closed') => (input.map(v => ({...v, "status": val, "symbol": v["symbol"] ? v["symbol"]: symbol})));

    const histDataStatus = addFlagStatusAndSymbol(hist_data, 'Closed');
    const curDataStatus = addFlagStatusAndSymbol(cur_data, 'Open');
    curDataStatus.reverse();
    return curDataStatus.concat(histDataStatus);
}

const HistoricalModalContent: FC<HistoricalModalContentProps> = (props) => {

    const {
        histData = [],
        currentData = [],
        startTime = '',
        endTime = '',
        isComposite = false,
        symbol,
        endFlag,
        product,
        stockSplit
    } = props;

    const combinedData = combineHistoricalCurrent(histData, currentData);

    const histDataSplit = (stockSplit === undefined || stockSplit.length === 0) ? addFlagPnl(histData) : formatAdjustStockSplit(histData, stockSplit);

    const extractAverage = (input: any[]) => {
        const [hits, duration, capitalGain, capitalGainPerDay] = input.reduce((acc, val) => {
            const startPoint = endFlag === 'Down' ? val['endPrice'] : val['price'];
            const endPoint = endFlag === 'Down' ? val['price'] : val['endPrice'];
            const flagDurationDay = (unixConverterEncode(val['endTime']) - unixConverterEncode(val['dts'])) / (60 * 60 * 24 * 1000);

            const capital_gain = val['endPrice'] - val['price'];
            const capital_gain_per_day = capital_gain / flagDurationDay;

            acc[1] += flagDurationDay;
            acc[2] += capital_gain;
            acc[3] += capital_gain_per_day

            if (startPoint - endPoint >= 0) {
                acc[0] += 1;
            }
            return acc
        }, [0, 0, 0, 0]);

        return [hits, duration, capitalGain, capitalGainPerDay]
    }

    const displayValues = (input: any[]) => {
        const [hits, duration, capitalGain, capitalGainPerDay] = input;

        const hit_rate = ((hits / histData.length) * 100).toFixed(2);
        const avg_duration = (duration / histData.length).toFixed(1);
        const avg_capital_gain = (capitalGain / histData.length).toFixed(2);
        const avg_capital_gain_per_day = (capitalGainPerDay / histData.length).toFixed(2);

        return [hit_rate, avg_duration, avg_capital_gain, avg_capital_gain_per_day]

    }

    const data = extractAverage(histDataSplit);
    const [hitRate, avgDuration] = displayValues(data)

    /**
     * Performance of flags with correct forecast direction
     * pos = positive
     */
    /*
    const positiveHisData = histDataSplit.filter(flag => ((flag.endPrice - flag.price) >= 0));
    const positiveData = extractAverage(positiveHisData)
    const [_hitRatePos, avgDurationPos, _avMovementPos, _avRawMovementPos] = displayValues(positiveData)
    */

    const productKey = getProductKey(product, isComposite);
    const {name} = StrategyKey[productKey]["FirstUpFirstEnd"]

    const dataDisplay: Metric[] = [{
        'name': 'Hit Rate',
        'description': 'Percentage of flags that correctly forecasted the price movement direction.',
        'value': hitRate.toString() + '%'
    }, {
        'name': 'Duration',
        'description': `Average time in days between the ${name}`,
        'value': `${avgDuration} days`
    },
    ]

    return (
        <Box sx={{padding: '20px 0px 5px 0px'}}>
            <StrategyModalContainer
                data={combinedData}
                strategyName={'FirstUpFirstEnd'}
                symbol={symbol}
                product={product}
                isComposite={isComposite}
                metrics={dataDisplay}
                startTime={startTime}
                endTime={endTime}/>
        </Box>
    )
}


const HistoricalModalContentStockSplit: FC<HistoricalModalContentProps> = (props) => {
    const {symbol} = props;
    const {data} = useGetStockSplitQuery({symbol}, {
        refetchOnMountOrArgChange: true,
    });

    if (data !== undefined) {
        return <HistoricalModalContent {...{...props, 'stockSplit': data}} />
    }
    return <HistoricalModalContent {...props} />
}

interface HistoricalModalProps {
    portfolioName?: string
    product: string
    symbol: string
    currentData: CurrentFlagData[]
    flagResponse?: HistoricalData[]
    startTime: string
    endTime: string
    openData: any[]
    endFlag: string
    setModalHandler: (e: any, id: any, callerId: any) => void
    modalIdOpen: boolean
    isComposite?: boolean
    isSuccess?: boolean
}

const FlagTabName = ({startFlag}) => {
    const {classes} = useStyles();

    const flagClass = startFlag === 'Up' ? classes.piUp : classes.rapDown;
    return (
        <div className={classes.tab}>
            Flag Use Case &nbsp;
            <span className={flagClass}>{startFlag}</span>&nbsp;to&nbsp;<span className={classes.end}>End</span>
    </div>)
}

const HistoricalModalInner: FC<HistoricalModalProps> = (props) => {
    const {
        product = '', symbol = '', endFlag = '', currentData = [], openData = [], setModalHandler, modalIdOpen,
        startTime = startOfYearFrom(MAX_YEARS),
        endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
        flagResponse = [], isSuccess = false, isComposite = false,
        portfolioName = ''
    } = props;

    const modalHandler = (e) => {
        const modalId = product + symbol;
        setModalHandler(e, modalId, "HistoricalModal");
    };

    const startFlag = startFlagFromProduct(product);

    const productDisplayName: any[] = Object.keys(productNamespace).includes(product) ? [...productNamespace[product].displayName, isComposite ? `Composite ${symbol}` : symbol] : [product, isComposite ? 'Composite' : '', symbol];

    const tabStartTime = startTime.slice(0, 10);
    const tabEndTime = endTime.slice(0, 10);

    const histData = flagResponse ?? [];
    const tabsData: TabsGeneralData[] = [{
        "name": <FlagTabName startFlag={startFlag}/>,
        "comp": isSuccess ? <HistoricalModalContentStockSplit symbol={symbol} product={product}
                                                              endFlag={endFlag} currentData={currentData}
                                                              histData={histData} openData={openData}
                                                              startTime={tabStartTime} endTime={tabEndTime}
                                                              isComposite={isComposite}
        /> : <Loading type='Loading Data'/>
    }]

    const downloadTitle = portfolioName === '' ? `${startFlag} to End Flags`:  `Composite ${startFlag} to End Flags`;

    const combinedData = combineHistoricalCurrent(histData, currentData);
    const downloadButton = <DownloadCsv data={combinedData} isComposite={isComposite}
                                        product={product} symbol={symbol} title={downloadTitle}
                                        quoteOnly={false} showTimezone={false}/>;

    const isLoading = !isSuccess

    return (
        <Fragment>
            <DialogueModal title={JSON.stringify(productDisplayName)}
                           open={modalIdOpen} modalHandler={modalHandler}
                           buttonDiv={downloadButton}>
                {isLoading ?
                    <Loading type='Loading Data'/>
                    : <TabsGeneral level={'primary'} data={tabsData}/>
                }
            </DialogueModal>
        </Fragment>
    )
}

const CompositeHistoricalModal: FC<HistoricalModalProps> = (props) => {
    const {
        product = '', symbol = '',
        startTime = startOfYearFrom(MAX_YEARS),
        endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
        portfolioName = ''
    } = props;

    const {data: flagResponse, isSuccess} = useGetCompositeHistoricalDataQuery({
        portfolioName,
        product,
        symbol,
        startTime,
        endTime
    }, {
        refetchOnMountOrArgChange: true,
    });

    return <HistoricalModalInner flagResponse={flagResponse} isSuccess={isSuccess} {...props}/>;
}

const HistoricalModal: FC<HistoricalModalProps> = (props) => {
    const {
        product = '', symbol = '',
        startTime = startOfYearFrom(MAX_YEARS),
        endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
    } = props;

    const {data: flagResponse, isSuccess} = useGetHistoricalDataQuery({product, symbol, startTime, endTime}, {
        refetchOnMountOrArgChange: true,
    });

    return <HistoricalModalInner flagResponse={flagResponse} isSuccess={isSuccess} {...props}/>;
}

const HistoricalModalWithStrategy: FC<HistoricalModalProps> = (props) => {
    const {
        isComposite = false,
        product = '', symbol = '', endFlag = '', currentData = [], openData = [], setModalHandler, modalIdOpen,
        startTime = startOfYearFrom(MAX_YEARS),
        endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
    } = props;

    const trade: Trade = product.includes('RAP') ? 'Short' : 'Long'

    const {data: flagResponse, isSuccess: isHistoricalDataSuccess} = useGetHistoricalDataQuery({
        product,
        symbol,
        startTime,
        endTime
    }, {
        refetchOnMountOrArgChange: true,
    });

    const {data: strategyData, isSuccess: isStrategyDataSuccess} = useGetStrategyDataQuery({
        product, symbol, name: 'FirstUpOpEnd', trade
    }, {
        refetchOnMountOrArgChange: true,
    });

    const [downloadData, setDownloadData] = useState<any[]>([]);
    const [tabIndex, setTabIndex] = useState(0);

    const modalHandler = (e) => {
        const modalId = product + symbol;
        setModalHandler(e, modalId, "HistoricalModal");
    };

    const onTabChange = (index) => {
        setTabIndex(index);
        if (index === 0 && flagResponse) {
            setDownloadData(flagResponse)
        } else if (index === 1) {
            if (strategyData) {
                setDownloadData(strategyData.flags)
            } else {
                setDownloadData([]);
            }
        }
    }

    useEffect(() => {
        if (tabIndex === 0 && flagResponse) {
            setDownloadData(flagResponse);
        }
    }, [tabIndex, flagResponse])

    const strategyName = tabIndex === 0 ? null : 'FirstUpOpEnd';

    const startFlag = startFlagFromProduct(product);
    const strategyEndFlag = endFlagFromProduct(product);

    const productDisplayName: any[] = Object.keys(productNamespace).includes(product) ? [...productNamespace[product].displayName, symbol] : [product, '', symbol];

    const tabStartTime = startTime.slice(0, 10);
    const tabEndTime = endTime.slice(0, 10);

    const tabsData: TabsGeneralData[] = [{
        "name": <FlagTabName startFlag={startFlag} />,
        "comp": <HistoricalModalContentStockSplit symbol={symbol} product={product}
                                                  endFlag={endFlag} currentData={currentData}
                                                  histData={flagResponse as any[]} openData={openData}
                                                  startTime={tabStartTime} endTime={tabEndTime}
                                                  isComposite={isComposite}
        />
    }, {
        "name": <FlagTabName startFlag={startFlag} />,
        "comp": isStrategyDataSuccess ? <StrategyRowContent symbol={symbol} product={product} trade={trade}
                                                            strategyData={strategyData} strategyName={'FirstUpOpEnd'}
                                                            startTime={tabStartTime} endTime={tabEndTime}
                                                            isComposite={isComposite}
        /> : <Loading type='Loading Data'/>
    }];

    const downloadTitles = [`${startFlag} to End Flags`, `${startFlag} to ${strategyEndFlag} Flags`]

    const downloadButton = <DownloadCsv data={downloadData} strategyName={strategyName} isComposite={isComposite}
                                        product={product} symbol={symbol} title={downloadTitles[tabIndex]}
                                        quoteOnly={false} showTimezone={false}/>;

    const isLoading = !(isHistoricalDataSuccess && isStrategyDataSuccess)

    return (
        <Fragment>
            <DialogueModal title={JSON.stringify(productDisplayName)}
                           open={modalIdOpen} modalHandler={modalHandler}
                           buttonDiv={downloadButton}>
                {isLoading ?
                    <Loading type='Loading Data'/>
                    : <TabsGeneral level={'primary'} data={tabsData} onTabChange={onTabChange}/>
                }
            </DialogueModal>
        </Fragment>
    )
}

interface HistoricalTableProps {
    startFlag: '' | 'Up' | 'Down'
    defaultFlagResponse: any[]
    flagResponse?: any[]
    portfolioName?: string,
    product: string,
}

const HistoricalTable: FC<HistoricalTableProps> = (props) => {
    const {classes} = useStyles();

    const {flagResponse = [], startFlag = '', defaultFlagResponse = [], product} = props;

    return (
        <Table style={{'width': '100%'}} className={classes.modalContent}>
            <TableHead>
                <TableRow>
                    <TableCell>{`${startFlag} Flag Issued`}</TableCell>
                    <TableCell>{`${startFlag} Flag Price`}</TableCell>
                    <TableCell>{`End Flag Issued `}</TableCell>
                    <TableCell>{`End Flag Price `}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {[...defaultFlagResponse, ...flagResponse].slice(0, 5).map((flag, index) => {
                    const numberDecimalPlaces = product.includes('FX') ? 5 : 2;
                    const price = typeof (flag['price']) === 'string' ? flag['price'] : flag['price'].toFixed(numberDecimalPlaces);
                    const endPrice = typeof (flag['endPrice']) === 'string' ? flag['endPrice'] : flag['endPrice'].toFixed(numberDecimalPlaces);

                    return (
                        <TableRow key={`${flag['symbol']}${index}`}>
                            <TableCell>{flag['dts']}</TableCell>
                            <TableCell>{price}</TableCell>
                            <TableCell>{flag['endTime']}</TableCell>
                            <TableCell>{endPrice}</TableCell>
                        </TableRow>
                    )
                })}

            </TableBody>
        </Table>
    );
};

interface HistoricalWindowProps {
    startFlag: '' | 'Up' | 'Down'
    defaultFlagResponse: any[]
    flagResponse?: any[]
    portfolioName?: string,
    product: string,
    symbol: string,
}

/// Expandable historical composite flags table
const CompositeHistoricalTable: FC<HistoricalWindowProps> = (props) => {
    const {product, symbol, portfolioName = ''} = props;

    const startTime = startOfYearFrom(MAX_YEARS);
    const endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";

    const {data: flagResponse, isSuccess} = useGetCompositeHistoricalDataQuery({
        portfolioName,
        product,
        symbol,
        startTime,
        endTime
    }, {
        refetchOnMountOrArgChange: true,
    });

    if (isSuccess) {
        return <HistoricalTable flagResponse={flagResponse} {...props}/>
    } else {
        return <Loading type='Loading Data'/>
    }
}

/// Expandable historical flags table
const HistoricalWindow: FC<HistoricalWindowProps> = (props) => {
    const {product, symbol} = props;

    const startTime = startOfYearFrom(MAX_YEARS);
    const endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";

    const {data: flagResponse, isSuccess} = useGetHistoricalDataQuery({product, symbol, startTime, endTime}, {
        refetchOnMountOrArgChange: true,
    });

    if (isSuccess) {
        return <HistoricalTable flagResponse={flagResponse} {...props}/>
    } else {
        return <Loading type='Loading Data'/>
    }
}

type HistoricalDisplay = 'snapshot' | 'modal';

interface CurrentFlagData {
    dts: string,
    price: number,
    endTime: number | 'Open',
    endPrice: number | 'Open'
}

interface HistoricalSnapShotProps {
    portfolioName?: string
    product: string
    symbol: string
    currentData: IFlagRust[]
    openFlags: any[]
    type: HistoricalDisplay
    setModalHandler: (e: any, id: any, callerId: any) => void
    modalOpen: boolean
}

export const CompositeHistoricalSnapShot: FC<HistoricalSnapShotProps> = (props) => {

    const {
        product = '',
        symbol = '',
        currentData = [],
        openFlags = [],
        type,
        setModalHandler,
        modalOpen,
        portfolioName = ''
    } = props;

    const startTime = startOfYearFrom(MAX_YEARS);
    const endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";

    const defaultFlagResponse: CurrentFlagData[] = currentData.map(flag => ({
        'dts': unixConverter(flag['ts']),
        'price': flag['price'],
        'endTime': 'Open',
        'endPrice': 'Open'
    }))

    const endFlag = endFlagFromProduct(product);
    const startFlag = startFlagFromProduct(product);

    const get_display_window = (display_type) => {
        if (display_type === 'snapshot') {
            return (<CompositeHistoricalTable startFlag={startFlag} defaultFlagResponse={defaultFlagResponse}
                                               product={product}
                                               symbol={symbol} portfolioName={portfolioName}/>)
        } else {
            return (
                <CompositeHistoricalModal product={product} startTime={startTime}
                                          endTime={endTime} symbol={symbol} currentData={defaultFlagResponse}
                                          openData={openFlags} endFlag={endFlag} setModalHandler={setModalHandler}
                                          modalIdOpen={modalOpen} portfolioName={portfolioName} isComposite={true}/>
            )
        }
    }

    return get_display_window(type);
}

const HistoricalSnapShot: FC<HistoricalSnapShotProps> = (props) => {

    const {
        product = '',
        symbol = '',
        currentData = [],
        openFlags = [],
        type,
        setModalHandler,
        modalOpen,
    } = props;

    const startTime = startOfYearFrom(MAX_YEARS);
    const endTime = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";

    const defaultFlagResponse: CurrentFlagData[] = currentData.map(flag => ({
        'dts': unixConverter(flag['ts']),
        'price': flag['price'],
        'endTime': 'Open',
        'endPrice': 'Open'
    }))

    const endFlag = endFlagFromProduct(product);
    const startFlag = startFlagFromProduct(product);

    // FIXME: enabled when first up to first down works
    const strategySupported = false; //startFlag === 'Up';

    const get_display_window = (display_type) => {
        if (display_type === 'snapshot') {
            return (<HistoricalWindow startFlag={startFlag} defaultFlagResponse={defaultFlagResponse} product={product}
                                      symbol={symbol}/>)
        } else {
            // FIXME should currentData be currentData?
            if (strategySupported) {
                return (
                    <HistoricalModalWithStrategy product={product} startTime={startTime}
                                                 endTime={endTime} symbol={symbol} currentData={defaultFlagResponse}
                                                 openData={openFlags} endFlag={endFlag}
                                                 setModalHandler={setModalHandler} modalIdOpen={modalOpen}/>
                )
            } else {
                return (
                    <HistoricalModal product={product} startTime={startTime}
                                     endTime={endTime} symbol={symbol} currentData={defaultFlagResponse}
                                     openData={openFlags} endFlag={endFlag} setModalHandler={setModalHandler}
                                     modalIdOpen={modalOpen}/>
                )
            }
        }
    }

    return get_display_window(type);
}

export default HistoricalSnapShot;
